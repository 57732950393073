.p-layout-main {
    display: flex;
    flex-wrap: wrap;
    background-color: #0f1519;
    height: 100%;

    &__sidebar {
        display: none;

        @media (min-width: 992px) {
            width: 100%;
            display: flex;
            flex-direction: column;
            background: #181818;
            max-height: 100vh;
            position: sticky;
            top: 0;
            width: 450px;
            order: 1;
            flex-shrink: 0;
        }
    }

    &__main {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow-y: auto;
        height: 100%;
    }
}
