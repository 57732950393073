.p-footer {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid var(--gray-500);
    padding: 16px;
    margin-bottom: 50px;
    flex: 0 0 auto;
    align-items: center;

    &__copy {
        font-style: normal;
        font-weight: 400;
        font-size: var(--font-base);
        line-height: 18px;
        color: var(--gray-200);
    }

    &__snLinks {
        display: flex;
        gap: 8px;
    }

    &__SNLink {
        display: inline-block;
        align-self: center;
        padding: 12px;
        border-radius: 4px;
        color: var(--gray-200);
        line-height: 0;

        &:hover {
            color: #fff;
            background-color: hsla(0, 0%, 100%, 0.05);
        }
    }
}