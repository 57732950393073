@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

:root {
  --offset-container: 16px;
}

#root {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
}

html {
  height: 100%;
  width: 100%;
  min-width: 320px;
  color: white;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #0f1519;
  height: auto;
  width: 100%;
  min-width: 320px;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  width: 100%;
  max-width: 1480px;
  padding-left: var(--offset-container);
  padding-right: var(--offset-container);
  margin-left: auto;
  margin-right: auto;
}

a:hover {
  @apply text-hoverBlue;
}

.p-space-block {
  display: flex;
}

.btn {
  @apply inline-block w-full rounded bg-blue px-4 py-2 text-center hover:text-cyan-300;
}

.btn:disabled {
  @apply bg-gray-800 hover:text-current;
}

.btnGhost {
  @apply inline-block w-full rounded border border-gray-400 bg-transparent px-4 py-2 text-center hover:border-gray-50 hover:text-cyan-300;
}

.btnGhostRed {
  @apply inline-block w-full rounded border border-gray-400 bg-transparent px-4 py-2 text-center hover:border-red-500 hover:text-red-500;
}

.btnIcon {
  @apply inline-block w-full rounded bg-transparent p-2 text-center hover:text-hoverBlue;
}
