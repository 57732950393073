.p-header {
    padding: var(--gap-m);
    flex: 0 0 auto;

    &__user {
        display: flex;
        align-items: center;
        width: 100%;
    }

    &__userPhoto {
        width: 48px;
        height: 48px;
        border-radius: 75%;
        margin-right: 12px;
    }

    &__userName {
        font-size: var(--font-large);
    }

    &__userType {
        font-size: var(--font-small);
        color: var(--gray-200);
    }

    &__userExitButton {
        margin-left: auto;
        padding: 16px;
        background: none;
        border: none;
        border-radius: 8px;
        align-self: center;
        line-height: 0;
        cursor: pointer;
        color: var(--gray-200);

        &:hover {
            color: #fff;
            background-color: hsla(0, 0%, 100%, 0.05);
        }
    }

    &__header-wrap {
        min-height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}