#root {
    --gap-m: 32px;
    --gap-s: 16px;
    --gray-200: #c4c4c4;
    --gray-500: #686868;
    --font-small: 14px;
    --font-base: 16px;
    --font-large: 20px;

    // mobile
    @media (max-width: 768px) {
        --gap-m: 12px;
        --gap-s: 8px;
        --font-small: 12px;
        --font-base: 14px;
        --font-large: 16px;
    }


    font-size: var(--font-base);
}

.p-MainPage {
    color: #fff;
    display: flex;
    background-color: #000;
    height: 100vh;
    background-image: linear-gradient(180deg,
            #000000 1.97%,
            #1c2d0e 10.41%,
            #4c361d 15.84%,
            #b07250 31.85%,
            #c98353 48.75%,
            #cf8958 81.66%,
            #cc8557 100%);

    &__side {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        padding: var(--gap-m);
        background-position: center;
        background-size: cover;
    }

    &__playbookName {
        font-style: normal;
        font-weight: 700;
        font-size: 100px;
        color: #ffffff;
        margin-top: auto;
        text-align: center;
        margin-bottom: var(--gap-m);
    }

    &__main {
        display: flex;
        flex-direction: column;
        max-width: 500px;
        width: 100%;
        flex: 0 0 auto;
        background-color: #0f1519;
    }

    &__ButtonCategory {
        display: flex;
        width: 100%;
        border-radius: 16px;
        overflow: hidden;
        background-color: #000;
        margin-bottom: 16px;
        height: 116px;
        color: #fff;
        text-decoration: none;
        background-size: cover;
        background-position: left center;

        &:hover {
            color: #fff;
        }

        &--theme {
            &_blue {
                &:hover {
                    .p-MainPage__bcBodySide {
                        background: hsla(200, 83%, 43%, 0.9);
                    }
                }
            }

            &_orange {
                &:hover {
                    .p-MainPage__bcBodySide {
                        background: hsla(10, 92%, 50%, 0.9);
                    }
                }
            }

            &_transparent {
                border: 2px solid #fff;
                background-color: transparent;

                &:hover {
                    .p-MainPage__bcBodySide {
                        background: #000;
                    }
                }
            }
        }
    }

    &__bcSide {
        display: flex;
        width: 64px;
        background-color: #a0a0a0;
        align-items: center;
        justify-content: center;

        & svg {
            width: 32px;
            height: auto;
        }

        &--theme {
            &_blue {
                background-image: linear-gradient(90deg, #62c1ef 0%, #138eca 100%);
            }

            &_orange {
                background-image: linear-gradient(90deg, #f5880c 0%, #f5330c 100%);
            }

            &_transparent {
                border-right: 2px solid #fff;
                background-color: transparent;
            }
        }
    }

    &__bcBodySide {
        flex: 1 1 auto;
        padding: 16px;
        transition: background 0.2s;
    }

    &__bcBodyText {
        display: block;
        max-width: 170px;
        font-style: normal;
        font-weight: 700;
        font-size: var(--font-large);
    }

    // ==== media ====
    // mac
    @media (max-width: 1440px) {
        &__main {
            max-width: 420px;
        }
    }

    // tablet
    @media (max-width: 992px) {
        &__playbookName {
            font-size: 64px;
            line-height: 87px;
        }
    }

    // mobile
    @media (max-width: 768px) {
        &__main {
            max-width: none;
        }

        &__side {
            display: none;
        }
    }
}